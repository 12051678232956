










































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TranslationForm from '@/components/TranslationForm.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import { TranslationModel } from '@/common/models';
import { DefaultTranslationModel } from '@/common/constants';
import _ from 'lodash';

@Component({
  components: {
    TranslationForm,
    ConfirmationDialog,
  },
})
export default class Add extends Vue {
  translation: TranslationModel = _.cloneDeep(DefaultTranslationModel);
  addDialog = true;
  discardDialog = false;
  errorMessage = false;
  addLoading = false;

  savedScroll = 0;

  get addResult() {
    return this.$store.state.add.result;
  }

  get isChanged(): boolean {
    return !_.isEqual(this.translation, DefaultTranslationModel);
  }

  @Watch('addResult')
  addResultWatch() {
    if (this.addResult) {
      this.addLoading = false;
      if (this.addResult.success) {
        this.addDialog = false;
        this.$store.commit('updateAddResult', null);
        this.openTranslationsPage();
      } else {
        this.errorMessage = true;
      }
    }
  }

  mounted() {
    this.savedScroll = document.documentElement.scrollTop;
    (document.querySelector('.v-application') as any).style.marginTop = `-${this.savedScroll}px`;
    document.body.style.overflow = 'hidden';
  }

  destroyed() {
    (document.querySelector('.v-application') as any).style.marginTop = '';
    document.body.style.overflow = '';
    document.documentElement.scrollTop = this.savedScroll;
  }

  onDiscardClick() {
    if (this.isChanged) {
      this.discardDialog = true;
    } else {
      this.onDiscard(true);
    }
    this.closeKeyboard();
  }

  onAddClick() {
    this.addLoading = true;
    this.closeKeyboard();
    this.$store.dispatch('addTranslation', this.translation);
  }

  onDiscard(discard: boolean) {
    this.discardDialog = false;

    if (discard) {
      this.addDialog = false;
      this.openTranslationsPage();
    }
  }

  closeErrorMessage() {
    this.errorMessage = false;
  }

  closeKeyboard() {
    this.$store.commit('closeKeyboard');
  }

  private openTranslationsPage() {
    const query: any = {
      page: this.$store.state.translations.currentPage.toString(),
      force: 'true',
    };

    if (this.$store.state.translations.wordList) {
      query.wordlist = 'true';
    }

    this.$router.push({ name: 'home', query });
  }
}
